import React from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"

import Flex from "components/atoms/Flex"

import LinkedInIcon from "images/linkedin.svg"
import FacebookIcon from "images/facebook.svg"
import TwitterIcon from "images/twitter.svg"

const ShareButton = ({ url, service }) => {
  switch (service) {
    case "linkedin":
      return (
        <LinkedinShareButton url={url}>
          <Flex
            width={"32px"}
            height={"32px"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"180px"}
            bg={"background.0"}
          >
            <LinkedInIcon />
          </Flex>
        </LinkedinShareButton>
      )
    case "facebook":
      return (
        <FacebookShareButton url={url}>
          <Flex
            width={"32px"}
            height={"32px"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"180px"}
            bg={"background.0"}
          >
            <FacebookIcon />
          </Flex>
        </FacebookShareButton>
      )
    case "twitter":
      return (
        <TwitterShareButton url={url}>
          <Flex
            width={"32px"}
            height={"32px"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"180px"}
            bg={"background.0"}
          >
            <TwitterIcon />
          </Flex>
        </TwitterShareButton>
      )
    default:
      return null
  }
}

export default ShareButton
