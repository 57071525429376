import React from "react"
import { graphql } from "gatsby"
import { format } from "date-fns"
import styled from "styled-components"

import Link from "components/utils/Link"

import Layout from "components/page/Layout"
import SEO from "components/page/Seo"
import Image from "components/utils/Image"
import NewsletterSignup from "components/molecules/NewsletterSignup"

import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import Button from "components/atoms/Button"
import { Title, Label } from "components/atoms/Typography"
import RecentArticles from "components/sections/RecentArticles"

import ThemeSwitcher from "components/utils/ThemeSwitcher"
import RichText from "components/molecules/RichText"
import ArticleShare from "components/molecules/ArticleShare"

const StyledDownloadLink = styled(Link)`
  color: black;
  font-size: 14px;
`

const Gradient = styled.div`
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 50;
`

const Article = ({ data, location }) => {
  const site = data.contentfulSite
  const images = data.allContentfulAsset.nodes
  const {
    title,
    description,
    image,
    text,
    similarArticles,
    shareArticleServices,
  } = data.contentfulArticle
  const date = new Date(data.contentfulArticle.date)
  const dateOnly = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000
  )
  return (
    <Layout site={site}>
      <SEO
        // favicon={site.favicon}
        title={title}
        metaDescription={description || site.metaDescription}
        image={image?.file.url || site.socialShareImage?.file.url}
      />
      <ThemeSwitcher theme="light">
        <article>
          <StandardPadding
            py={0}
            px={0}
            mt={{ _: 0, lg: 4 }}
            position={"relative"}
          >
            <Gradient />
            <Box width="100%" maxHeight={32} overflow={"hidden"}>
              <Image {...image} />
            </Box>
          </StandardPadding>
          <StandardPadding
            mt={"-200px"}
            mx={"auto"}
            py={0}
            pt={7}
            pb={9}
            px={{ _: 6, md: 0 }}
            maxWidth={46}
            position={"relative"}
            zIndex={60}
          >
            <Flex
              flexDirection={{ _: "column", sm: "row" }}
              justifyContent={"space-between"}
              alignItems={{ _: undefined, sm: "center" }}
              width={"100%"}
              mb={6}
            >
              <Label mb={{ _: 4, sm: 0 }} opacity={0.5}>
                {format(dateOnly, "MMMM d, yyyy")}
              </Label>
              {(data.contentfulArticle.pdf || shareArticleServices) && (
                <Flex alignItems={"center"}>
                  {shareArticleServices && (
                    <ArticleShare
                      services={shareArticleServices}
                      url={location.href}
                    />
                  )}
                  {data.contentfulArticle.pdf && (
                    <StyledDownloadLink
                      showDisclosure={false}
                      href={data.contentfulArticle.pdf.file.url}
                    >
                      <Flex
                        height={"32px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRadius={"180px"}
                        bg={"background.1"}
                        px={5}
                        ml={3}
                      >
                        Download PDF
                      </Flex>
                    </StyledDownloadLink>
                  )}
                </Flex>
              )}
            </Flex>
            <Title variant="h1" mb={5}>
              {title}
            </Title>
            <RichText text={text} images={images} mb={8} />
            <NewsletterSignup my={5} title="Sign up for our Newsletter!" card />
          </StandardPadding>
        </article>
        {similarArticles && (
          <RecentArticles
            centerLabel
            label="Similar Articles"
            sectionStyling={{
              paddingTop: "sm",
              paddingBottom: "sm",
              theme: "dark",
              background: 0,
            }}
            articles={similarArticles}
          />
        )}
      </ThemeSwitcher>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleBySlug($slug: String!, $images: [String!]!) {
    contentfulSite {
      title
      header {
        ...Header
      }
      footer {
        ...Footer
      }
      socialShareImage {
        file {
          url
        }
      }
    }
    contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      description
      shareArticleServices
      date
      pdf {
        file {
          url
        }
      }
      image {
        description
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
      }
      text {
        json
      }
      similarArticles {
        title
        date
        label
        slug
        location
        image {
          description
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    allContentfulAsset(filter: { file: { url: { in: $images } } }) {
      nodes {
        fluid(maxWidth: 704, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
